$engie-blue: #0af;
$engie-primary: #009de9;
$engie-secondary-green: #6ed2b1;
$engie-gradient: linear-gradient(
  135deg,
  $engie-blue 0.89%,
  $engie-secondary-green 94.05%
);
$engie-warn: #e74a50;
$engie-yellow: #faca08;
$engie-orange: #f78a31;
$color-solar: #faca08;
$color-wind: #007873;
$color-temperature: #001fa0;
